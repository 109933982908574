<template>
  <CBox bg="main" h="54px">
    <!-- <Cbox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
      <Loading :active="isLoading" :is-full-page="true" />
      <CFlex pt="5px" pb="5px" alignItems="center" justifyContent="space-between" maxW="1080px" mx="auto">
        <CFlex>
          <a href="https://gethired.id" style="vertical-align:middle">
            <img src="@/assets/img/logo.svg" />
          </a>
        </CFlex>
        <CStack
          display="flex"
          direction="row"
          :spacing="6">
          <ButtonOrange label="CARI KERJA" style="margin-right: 15px"></ButtonOrange>
          <c-menu>
            <c-menu-button bg="main" :_hover="{ bg: 'main' }" border-width="0px" boxShadow="0px" :_focus="{
              outline: 'none',
              boxShadow: 'none',
            }" right-icon="chevron-down" color="white">
              <CAvatar w="35px" h="35px" mr="5px"></CAvatar>
              Hi, {{ currentUser.shortName }}
            </c-menu-button>
            <c-menu-list>
              <c-menu-item as="a" :href="feURL+'/employee/profile'">Lihat Profile</c-menu-item>
              <c-menu-item as="a" :href="feURL+'/employee/setting'">Ubah Password</c-menu-item>
              <c-menu-item as="a" :href="feURL+'/employee/cv'">Pilih Desain CV</c-menu-item>
              <c-menu-item as="a" :href="feURL+'/employee/application'">Lamaran Saya</c-menu-item>
              <c-menu-item @click="$router.push({ name: 'Dashboard Web CV' })">Lihat Web CV</c-menu-item>
              <c-menu-item @click.native="logout()">Keluar</c-menu-item>
            </c-menu-list>
          </c-menu>
        </CStack>
      </CFlex>
    <!-- </Cbox> -->
  </CBox>
</template>

<script>
import { CBox, 
CFlex, 
CStack, CMenu, CMenuButton, CMenuList, CMenuItem } from "@chakra-ui/vue"
import ButtonOrange from "@/components/button/ButtonOrange.vue"
import { GET_USER, GET_JWT, PURGE_AUTH } from "@/store/auth.module"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from "@/ConfigProvider";

export default {
  name: "HeaderDashboard",
  props: {
    currentUser: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    CBox,
    CFlex,
    CStack,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    ButtonOrange,
    Loading
  },
  data() {
    return {
      isLoading: false,
      feURL: config.value('FE_URL'),
      webcvURL: config.value('WEBCV_URL')
    }
  },
  mounted() {
    // this.getData();
    // this.$store.dispatch(GET_USER)
  },
  computed: {
    // currentUser() {
    //   return this.$store.getters.currentUser;
    // },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
      window.location.href = this.feURL+"/logout";
    },
    async getData() {
      // if (!this.isAuthenticated) {
      this.isLoading = true;
      await this.$store.dispatch(GET_JWT);
      // }
      setTimeout(async () => {
        await this.$store.dispatch(GET_USER)
        this.isLoading = false;
        let status = false;

        if (this.currentUser.is_premium && !this.currentUser.premium_end) {
          status = true;
        } else if (this.currentUser.premium_end && new Date().getTime() < new Date(this.currentUser.premium_end).getTime()) {
          status = true;
        } else if (this.currentUser.trial_end && new Date().getTime() < new Date(this.currentUser.trial_end).getTime()) {
          status = true;
        } else if (this.currentUser.is_mentor) {
          status = true;
        }

        if (!status) window.location.href = config.value("FE_URL")+"/employee/profile";
      }, 100);
    }
  }
}
</script>